import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Calculator from './components/Calculator';
import OurWorks from './components/OurWorks';
import Footer from './components/Footer';
import './styles/index.scss'; // Подключаем стили, если нужно

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Calculator />
      <OurWorks />
      <Footer />
    </div>
  );
};

export default App;
