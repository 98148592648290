import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Hero = () => {

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: 1
  });

  const [isHeroButtonAnimated, setHeroButtonAnimated] = useState(false); 

  const fadeUpAnimation = {
    initial: { opacity: 0, y: 100 }, 
    animate: { opacity: inView ? 1 : 0, y: inView ? 0 : 100 },
    transition: {
      ease: [0.68, -0.55, 0.27, 1.55]
    }
  };

  const fadeAnimation = {
    initial: { opacity: 0}, 
    animate: { opacity: inView ? 1 : 0},
  };

  const dotAnimation = {
    initial: { top: '100%', opacity: 0,
    }, 
    animate: { top: inView ? '40%' : '100%', opacity: inView ? 1 : 0},
  };

  const gridAnimation = {
    initial: { height: 0}, 
    animate: { height: inView ? '100%' : 0},
  };

  return (
    <section id="hero" className="hero-section"  ref={ref}>
      <div className='background'>
        <div className='gradient fill'></div>
        <div className='gradient top'></div>
        <div className='gradient right'></div>
        <img src='./images/hero-image.jpg'/>
      </div>
      <div className='row-grid' ref={ref}>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .3, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .7, duration: 1,}}>
          <motion.div {...fadeAnimation}  transition={{ delay: .7, duration: 0.5,}} className='dot'></motion.div>
          <motion.div className='dot' {...dotAnimation} transition={{ delay: 1.6, duration: 0.7,}}>
            <p>которые украсят Ваш интерьер и прослужат до 100 лет</p>
          </motion.div>
        </motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: 1.1, duration: 1,}}></motion.div>
      </div>
      <div className='row-container'>
        <div className={'left-col'}>
          <motion.h1 {...fadeUpAnimation} transition={{ delay: 1, duration: 0.8}}>Делаем стеклянные перегородки в Астане</motion.h1>
          <motion.p {...fadeUpAnimation} transition={{ delay: 1.2, duration: 0.8}}><span>10 лет создаем стеклянные перегородки</span> для вашего интерьера</motion.p>
          <div className='buttons'>
            <motion.a href="#calculator"  {...fadeAnimation} transition={{ delay: 1.3, duration: 1.4}} onAnimationStart={() => setHeroButtonAnimated(true)}
            onAnimationComplete={() => setHeroButtonAnimated(false)}
            className={isHeroButtonAnimated ? 'no-transition cta' : 'cta'} >Рассчитать стоимость</motion.a>
            
            <motion.a href="https://api.whatsapp.com/send/?phone=77072123457&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%BF%D0%B8%D1%88%D1%83%20%D0%B2%D0%B0%D0%BC%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0&type=phone_number&app_absent=0" {...fadeAnimation} transition={{ delay: 1.4, duration: 1.4}} onAnimationStart={() => setHeroButtonAnimated(true)}
            onAnimationComplete={() => setHeroButtonAnimated(false)}
            className={isHeroButtonAnimated ? 'no-transition cta whatsapp' : 'cta whatsapp 1'} >
              <div className='icon-row'>
                <img src='./images/whatsapp.png' alt='WhatsApp Logo' className='whatsapp'/>
              </div>
              <div className='text-row'>Написать в WhatsApp</div>
            </motion.a>
          </div>
        </div>
        <div className='right-col'>
          <ul className='offer-items'>
            <motion.li {...fadeAnimation} transition={{ delay: 1.7, duration: 0.5}}>
              <span>Прозрачная смета</span>
              известная еще до начала всех работ
            </motion.li>
            <motion.li {...fadeAnimation} transition={{ delay: 1.9, duration: 0.5}}>
              <span>Любой размер</span>
              и сложность
            </motion.li>
            <motion.li {...fadeAnimation} transition={{ delay: 2, duration: 0.5}}>
              Изготовление
              <span>5-10 дней</span>
            </motion.li>
            <motion.li {...fadeAnimation} transition={{ delay: 2.2, duration: 0.5}}>
              <span>Установка сразу</span>
              в день доставки
            </motion.li>
            <motion.li {...fadeAnimation} transition={{ delay: 2.4, duration: 0.5}}>
              <span>Гарантия</span>
              на 24 месяца
            </motion.li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
