import React from 'react';
import { motion } from 'framer-motion';

const Header = () => {

  const fadeAnimation = {
    initial: { opacity: 0}, 
    animate: { opacity: 1},
  };

  return (
    <header className="header">
        <div className='row-container'>
            <motion.div className='header-element head-logo' {...fadeAnimation} transition={{ delay: 2.5, duration: 0.6,}}>
                <img src="./images/logo.png" alt="Alyans Zhihaz Logo" className="logo" />
            </motion.div>

            <motion.div className='header-element head-about' {...fadeAnimation} transition={{ delay: 2.6, duration: 0.6,}}>
                <p><span>10 лет создаем стеклянные перегородки</span> для вашего интерьера</p>
            </motion.div>

            <motion.div className='header-element head-designer-button' {...fadeAnimation} transition={{ delay: 2.7, duration: 0.6,}}>
                <a href='https://api.whatsapp.com/send/?phone=77072123457&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%20%D0%BF%D0%B8%D1%88%D1%83%20%D0%B2%D0%B0%D0%BC%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D1%81%D0%BE%D1%82%D1%80%D1%83%D0%B4%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0&type=phone_number&app_absent=0'>Для Дизайнера</a>
            </motion.div>

            <motion.div className='header-element head-social-networks' {...fadeAnimation} transition={{ delay: 2.8, duration: 0.6,}}>
                <div className='col-left'>
                    <p>Если что, мы тут <span>Online</span></p>
                </div>
                <div className='col-right'>
                    <a href='https://api.whatsapp.com/send/?phone=77072123457&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%BF%D0%B8%D1%88%D1%83%20%D0%B2%D0%B0%D0%BC%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0&type=phone_number&app_absent=0'><img src='./images/whatsapp.png' alt='WhatsApp Logo' className='whatsapp'/></a>
                    <a href='https://www.instagram.com/alyans_zhihaz/'><img src='./images/instagram.png' alt='Instagram Logo' className='instagram'/></a>
                </div>
            </motion.div>

            <motion.div className='header-element head-phone-number' {...fadeAnimation} transition={{ delay: 2.9, duration: 0.6,}}>
                <p className='schedule'>Звоните: пн-сб 9:00-20:00</p>
                <p className='phone-number'><a href='tel:+77072123457'>+7 (707) 212-34-57</a></p>
                {/* <p className='schedule-call'><a href="#">заказать обратный звонок</a></p> */}
            </motion.div>
        </div>
    </header>
  );
};

export default Header;
