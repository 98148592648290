import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import InputMask from 'react-input-mask';

const Calculator = () => {
 
  const [currentStep, setCurrentStep] = useState(1);
  const [partitionTypePrice, setPartitionTypePrice] = useState(null);
  const [glassTypePrice, setGlassTypePrice] = useState(null);
  const [selectedPartitionType, setSelectedPartitionType] = useState(null); // для active на этапе 1
  const [selectedGlassType, setSelectedGlassType] = useState(null);         // для active на этапе 2
  const [dimensions, setDimensions] = useState({ width: 80, height: 100 });
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState('');
  const [contactInfo, setContactInfo] = useState({ name: '', phone: '' });

  const [response, setResponse] = useState(null);
  const [responseError, setResponseError] = useState(null);

  const partionsLists = [
    { id: 1, imgID: 1, name: "С раздвижными дверями", price: 50000, delay: 0.4},
    { id: 2, imgID: 2, name: "Телескопическая (каскадная)",  price: 95000, delay: 0.5  },
    { id: 3, imgID: 3, name: "Откатная",  price: 90000, delay: 0.6  },
    { id: 4, imgID: 4, name: "С распашными дверями",  price: 100000, delay: 0.7  },
    { id: 5, imgID: 5, name: "Складная (гармошка, книжка)",  price: 95000, delay: 0.8  },
    { id: 6, imgID: 1, name: "Нужно другое",  price: 60000, delay: 0.9  },
  ];

  const glassesLists = [
    { id: 1, imgID: 1, name: "Прозрачное", price: 25000, delay: 0.4 },
    { id: 2, imgID: 2, name: "Матовое",  price: 28000, delay: 0.5  },
    { id: 3, imgID: 3, name: "Графит",  price: 30000, delay: 0.6  },
    { id: 4, imgID: 4, name: "Бронза",  price: 30000, delay: 0.7  },
    { id: 5, imgID: 5, name: "Рифленое",  price: 60000, delay: 0.8  },
    { id: 6, imgID: 1, name: "Нужно другое",  price: 30000, delay: 0.9  },
  ];

  const cleanPhoneNumber = (phone) => {
    return phone.replace(/[^+\d]/g, '');
  }


//   <div className='item'>
//   <h4>Тип :</h4> 
//   <img src={`./images/partions-type/${selectedPartitionType}.jpg`} />
//   <h5>{partionsLists[selectedPartitionType - 1].name}</h5>
// </div>

// <div className='item'>
//   <h4>Cтекло:</h4> 
//   <img src={`./images/glass-type/${selectedGlassType}.jpg`} />
//   <h5>{glassesLists[selectedGlassType - 1].name}</h5>
// </div>

// <div className='item'>
//   <h4>Высота:</h4> 
//   <h5>{dimensions.height} см.</h5>
// </div>

// <div className='item'>
//   <h4>Ширина:</h4> 
//   <h5>{dimensions.width} см.</h5>
// </div>

// <div className='item price'>
//   <h4><strong>Цена:</strong></h4> 
//   <h5>{totalPrice} ₸</h5>
// </div>

  
useEffect(() => {
  if(currentStep == 4 && totalPrice) {
    submitCalcData();
  }
}, [totalPrice,currentStep]);


  // Рассчитываем итоговую стоимость
  const calculatePrice = () => {
    const pricePerSquareMeter = (partitionTypePrice + glassTypePrice);
    const area = (dimensions.width / 100) * (dimensions.height / 100);
    const rawTotalPrice = pricePerSquareMeter * area;
    setTotalPrice(Math.round(rawTotalPrice).toLocaleString('ru-RU'));


    const telNumber = cleanPhoneNumber(contactInfo.phone);

    if(currentStep == 3) {
      if (!dimensions.width) {
        setError('Пожалуйста, введите ширину');
        return;
      }

      if (!dimensions.height) {
        setError('Пожалуйста, введите высоту');
        return;
      }

      if (contactInfo.name == '') {
        setError('Пожалуйста, введите имя');
        return;
      }

      if (contactInfo.phone == '' || telNumber.length < 12) {
        
        setError('Пожалуйста, введите номер телефона');
        return;
      }
      
      setCurrentStep(4);      
    }
  };

  

  const resetSteps = () => {
    setError(''); 
    setCurrentStep(1);

    setPartitionTypePrice(null);
    setGlassTypePrice(null);

    setSelectedPartitionType(null);
    setSelectedGlassType(null);
  };  

  const nextStep = async () => {
    if (currentStep === 1 && !partitionTypePrice) {
      setError('Пожалуйста, выберите тип перегородки');
      return;
    }
    if (currentStep === 2 && !glassTypePrice) {
      setError('Пожалуйста, выберите тип стекла');
      return;
    }

    if(currentStep === 4) {
      calculatePrice();
      // console.log('total:' + totalPrice)
      // submitFormData(totalPrice);
    }

    setError(''); // Очистка ошибки при правильном выборе
    setCurrentStep(currentStep + 1);
   

  };

  const backStep = () =>{
    setCurrentStep(currentStep - 1);
    setError('');
  };

  // Функция для выбора типа перегородки
  const selectPartitionType = (id, price) => {
    setPartitionTypePrice(price);
    setSelectedPartitionType(id); // Установка активного типа перегородки
  };

  // Функция для выбора типа стекла
  const selectGlassType = (id, price) => {
    setGlassTypePrice(price);
    setSelectedGlassType(id); // Установка активного типа стекла
  };


  const handleDimensionChange = (e) => {
    const { name, value } = e.target;
    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      [name]: parseFloat(value) || 0,
    }));
  };

  // Обновление контактной информации
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const submitCalcData = async () => {

    // https://alyans-zhihaz.kz/api.php

    console.log('total sum: ' . total);
    const url = 'https://alyans-zhihaz.kz/api.php'; // Замените на нужный URL
    const phone = cleanPhoneNumber(contactInfo.phone);
    const data = {
        type: partionsLists[selectedPartitionType - 1].name,
        glass: glassesLists[selectedGlassType - 1].name,
        height: dimensions.height,
        width: dimensions.width,
        price: totalPrice,
        name: contactInfo.name,
        phone: phone,
    };
    const apiURL = 'https://alyans-zhihaz.kz/api.php';

    fetch(apiURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // убедитесь, что data содержит правильные поля
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    })
    .then(data => {
        console.log('Success:', data);
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }




  // /////////////////////////////



  const { ref, inView } = useInView({
    threshold: 0.5, // Процент видимости, который нужно достичь, чтобы считать элемент видимым
    triggerOnce: 1
  });


  const gridAnimation = {
    initial: { height: 0}, 
    animate: { height: inView ? '100%' : 0},
  };

  const fadeAnimation = {
    initial: { opacity: 0}, 
    animate: { opacity: inView ? 1 : 0},
  };

  const fadeUpAnimation = {
    initial: { opacity: 0, y: 100 }, 
    animate: { opacity: inView ? 1 : 0, y: inView ? 0 : 100 },
    transition: {
      ease: [0.68, -0.55, 0.27, 1.55]
    }
  };

  return (
    <section id="calculator" className="calculator-section">
     <div className='row-grid' ref={ref}>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .3, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .7, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: 1.1, duration: 1,}}></motion.div>
      </div>
      <div className='row-container' >
        <div className='heading' ref={ref}>
          <motion.h2 {...fadeAnimation}  transition={{ delay: 1.5, duration: 0.7,}}>Узнайте стоимость стеклянной перегородки по вашим параметрам и размерам</motion.h2>
          <div className='bonus-info'>
            <motion.h3 {...fadeAnimation}  transition={{ delay: 1.7, duration: 0.5,}}>В конце теста вы получите:</motion.h3>
            <ul>
              <motion.li {...fadeUpAnimation}  transition={{ delay: 1.9, duration: 0.6,}}>Расчет стоимости перегородки</motion.li>
              <motion.li {...fadeUpAnimation}  transition={{ delay: 2, duration: 0.6,}}>Бесплатная консультация нашего инженера</motion.li>
              <motion.li {...fadeUpAnimation}  transition={{ delay: 2.1, duration: 0.6,}}>Бесплатный замер</motion.li>
            </ul>
          </div>
        </div>
        <div className='disklamer-items' >
          <motion.div className='item' {...fadeAnimation}  transition={{ delay: 2.4, duration: 0.5,}}>
            <span className='number'>01</span>
            <div className='text-wrap'>
              <p><strong>Ответьте на 4 простых вопроса,</strong> которые помогут нам рассчитать стоимость</p>
            </div>
          </motion.div>

          <motion.div className='item' {...fadeAnimation}  transition={{ delay: 2.6, duration: 0.5,}}>
            <span className='number'>02</span>
            <div className='text-wrap'>
              <p><strong>Учитывайте что стоимость приблизительная</strong> и может немного отличаться на финальном замере</p>
            </div>
          </motion.div>
        </div>

        <div className='calc-wrap'>
          <div className='left-col'>
            <motion.div className='progress-bar' {...fadeAnimation}  transition={{ delay: 2.8, duration: 0.5,}}>
              <div className='progress' style={{width: currentStep * 25 + "%"}}></div>
              <span className='progress-title'>этап {currentStep} из 4</span>
            </motion.div>

            <div className='calc-steps-wrapper'>
              {/* step 1 */}
              {currentStep === 1 && (
                <motion.div {...fadeAnimation}  transition={{ delay: 3.2, duration: 0.5,}}>
                  <motion.h3 {...fadeAnimation}  transition={{ delay: 3, duration: 0.5,}}>Выберите тип перегородки:</motion.h3>
                  <motion.ul >
                    {partionsLists.map((option) => (
                      <motion.li
                        key={option.id}
                        onClick={() => selectPartitionType(option.id, option.price)}
                        className={selectedPartitionType === option.id ? 'active' : ''}
                        {...fadeAnimation}
                        transition={{ delay: option.delay, duration: 0.35 }}
                      >
                        <img src={`./images/partions-type/${option.imgID}.jpg`} alt={option.name} />
                        <h4>{option.name}</h4>
                      </motion.li>
                    ))}
                  </motion.ul>
                </motion.div>
              )}

              {/* step 2 */}
              {currentStep === 2 && (
                <div>
                  <h3>Выберите тип стекла:</h3>
                  <ul>
                    {glassesLists.map((option) => (
                      <motion.li
                        key={option.id}
                        onClick={() => selectGlassType(option.id, option.price)}
                        className={selectedGlassType === option.id ? 'active' : ''}
                        {...fadeAnimation}
                        transition={{ delay: option.delay, duration: 0.35 }}
                      >
                        <img src={`./images/glass-type/${option.imgID}.jpg`} alt={option.name} />
                        <h4>{option.name}</h4>
                      </motion.li>
                    ))}
                  </ul>
                </div>
              )}

              {/* step 3 */}
              {currentStep === 3 && (
                <div>
                  <h3>Выберите размеры:</h3>
                  <div className='sizes inputs'>
                    <label>
                      Ширина (cм):
                      <input
                        type="text"
                        name="width"
                        min="80"
                        value={dimensions.width}
                        onChange={handleDimensionChange}
                      />
                    </label>

                    <label>
                      Высота (cм):
                      <input
                        type="text"
                        name="height"
                        min="100"
                        value={dimensions.height}
                        onChange={handleDimensionChange}
                      />
                    </label>
                  </div>

                  <div className='data inputs'>
                    <label>
                      Ваше имя:
                      <input
                        type="text"
                        name="name"
                        onChange={handleContactChange}
                        value={contactInfo.name}
                        
                      />
                    </label>

                    <label>
                      Номер телефона:
                      <InputMask 
                        name="phone" 
                        mask="+7 (999) 999-99-99" 
                        placeholder="+7 (___) ___-__-__" 
                        onChange={handleContactChange}
                        value={contactInfo.phone}
                        
                      />
                    </label>
                  </div>
                  
                </div>
              )}

              {/* step 4 */}
              {currentStep === 4 && (
                <div className='results'>
                  <h3>Результат:</h3>

                  <div className='item'>
                    <h4>Тип :</h4> 
                    <img src={`./images/partions-type/${selectedPartitionType}.jpg`} />
                    <h5>{partionsLists[selectedPartitionType - 1].name}</h5>
                  </div>

                  <div className='item'>
                    <h4>Cтекло:</h4> 
                    <img src={`./images/glass-type/${selectedGlassType}.jpg`} />
                    <h5>{glassesLists[selectedGlassType - 1].name}</h5>
                  </div>

                  <div className='item'>
                    <h4>Высота:</h4> 
                    <h5>{dimensions.height} см.</h5>
                  </div>

                  <div className='item'>
                    <h4>Ширина:</h4> 
                    <h5>{dimensions.width} см.</h5>
                  </div>

                  <div className='item price'>
                    <h4><strong>Цена:</strong></h4> 
                    <h5>{totalPrice} ₸</h5>
                  </div>

                
                </div>
              )}
            </div>

            <motion.div className='calc-footer' {...fadeAnimation}  transition={{ delay: 3.4, duration: 0.5,}}>
                {error && <p style={{ color: 'red', width: "100%"}}>{error}</p>}
                <div className='text-wrap'>
                  <p>
                    <strong>Выберите вариант ответа</strong> и нажмите кнопку “Далее”
                  </p>
                </div>
                <div className='buttons-wrap'>
                  { currentStep > 1 && (
                    <button className='back' onClick={backStep}>Назад</button>
                  )}

                  { currentStep < 3 && (
                    <button className='next' onClick={nextStep}>Далее</button>
                  )}

                  { currentStep == 3 && (
                    <button className='next' onClick={calculatePrice}>Рассчитать стоимость</button>
                  )}

                  { currentStep == 4 && (
                    <button className='next' onClick={resetSteps}>Заново</button>
                  )}
                  {/* <button className='next' onClick={nextStep}>Далее</button> */}
                </div>
       
                
            </motion.div>

            {/* <p style={{color: '#fff'}}>
              Name: {contactInfo.name} <br/>
              Phone: {contactInfo.phone}
            </p> */}

          </div>

          <motion.div className='right-col' {...fadeAnimation} transition={{ delay: 2, duration: 0.35 }}>
            <div className='kirill'>
              <img src='./images/kirill.jpg' />
              <div className='text-wrap'>
                <h3>Здравствуйте, меня зовут Сергей, я старший мастер</h3>
                <p>По результатам ваших ответов я сориентирую вас по стоимости, отвечу на все вопросы</p>
              </div>
            </div>
          </motion.div>
          
        </div>
      </div>
    </section>
  );
};

export default Calculator;
