import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'; // Добавляем стили для тултипов

const About = () => {

  

  const { ref, inView } = useInView({
    threshold: 0.5, // Процент видимости, который нужно достичь, чтобы считать элемент видимым
    triggerOnce: 1
  });

  const gridAnimation = {
    initial: { height: 0}, 
    animate: { height: inView ? '100%' : 0},
  };

  const fadeAnimation = {
    initial: { opacity: 0}, 
    animate: { opacity: inView ? 1 : 0},
  };

  return (
    <section id="about" className="about-section">
      <div className='row-grid' ref={ref}>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .3, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .7, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: 1.1, duration: 1,}}></motion.div>
      </div>
      <div className='row-container'>
        <div className='heading'>
          <motion.h2 {...fadeAnimation}  transition={{ delay: .7, duration: 0.5,}}>Добавьте нотку роскоши и изысканности в свой дом с помощью стеклянных перегородок</motion.h2>
          <motion.p {...fadeAnimation}  transition={{ delay: .9, duration: 0.5,}}><span>Наведите на плюсик, чтобы узнать более подробную информацию</span></motion.p>
        </div>

        <motion.div className='interactive-image'  {...fadeAnimation}  transition={{ delay: 1.3, duration: 0.8,}}>
          <div className='image-block'>
            <img src='./images/Добавьте-нотку-роскоши-и-изысканности-в-свой-дом-с-помощью-стеклянных-перегородок.jpg' alt='Стеклянные перегородки в Астане'/>
          </div>
          <div className='interactive-elements'>
            <motion.div
              className='tool tool-1'
              data-tip
              data-tooltip-id="tooltip-1"
              style={{
                position: 'absolute',
                top: '60%',
                left: '40%',
                cursor: 'pointer',
              }}
              {...fadeAnimation}  transition={{ delay: 2.3, duration: 0.5,}}
            >
              <span>+</span>
            </motion.div>

            <Tooltip id="tooltip-1" place="right" effect="solid">
              <h4>Закаленное стекло до 8 мм</h4>
              <p>Мы используем прочное и безопасное закалённое стекло, которое надёжно защищает вас и ваших детей. В ассортименте множество видов стекла на любой вкус и потребности. </p>
            </Tooltip>


            <motion.div
              className='tool tool-2'
              data-tip
              data-tooltip-id="tooltip-2"
              style={{
                position: 'absolute',
                top: '10%',
                left: '40%',
                cursor: 'pointer',
              }}
              {...fadeAnimation}  transition={{ delay: 1.6, duration: 0.5,}}
            >
              <span>+</span>
            </motion.div>

            <Tooltip id="tooltip-2" place="bottom" effect="solid">
              <h4>Подвесной профиль для перегородок</h4>
              <p>Надежный профиль, выдерживающий нагрузку до 600 кг. Подходит для установки доводчиков и систем синхронного открывания. Стильный и лаконичный дизайн впишется в любой современный интерьер. </p>
            </Tooltip>

            <motion.div
              className='tool tool-3'
              data-tip
              data-tooltip-id="tooltip-3"
              style={{
                position: 'absolute',
                top: '40%',
                left: '27%',
                cursor: 'pointer',
              }}
              {...fadeAnimation}  transition={{ delay: 1.9, duration: 0.5,}}
            >
              <span>+</span>
            </motion.div>

            <Tooltip id="tooltip-3" place="right" effect="solid">
              <h4>Прочный профиль</h4>
              <p>Профиль для лофт-перегородок изготовлен из прочного толстостенного алюминия, что обеспечивает надёжность и долговечность. </p>
            </Tooltip>


          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
